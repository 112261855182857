import React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Layout from "@components/Layout";
import Header from "@components/Header";
import SEO from "@components/SEO";
import PageHeading from "@components/PageHeading";
import Card from "@components/Card";
import Link from "@components/Link";

const BlogPage = ({ data, location }) => {
  const { t } = useTranslation();
  const posts = data.posts?.edges?.map(({ node }) => {
    return {
      ...node,
      thumbnail: data.thumbnails?.nodes?.find((n) =>
        node.frontmatter.slug.includes(n.name)
      ),
    };
  });

  return (
    <Layout>
      <SEO
        title={t("blog.title")}
        description={t("blog.description")}
        pathname={location.pathname}
      />
      <Header />
      <main>
        <div className="main-content-wrapper">
          <PageHeading>{t("blog.heading")}</PageHeading>
          <div className="blog-posts">
            {posts.map((node, i) => (
              <Link
                key={`blog-post-${i}`}
                href={`/blog/${node.frontmatter.slug}`}
              >
                <Card
                  image={
                    <GatsbyImage image={getImage(node.thumbnail)} alt="" />
                  }
                  heading={<h2>{node.frontmatter.title}</h2>}
                />
              </Link>
            ))}
          </div>
        </div>
      </main>
    </Layout>
  );
};

BlogPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export const query = graphql`
  query BlogPosts($locale: String!) {
    posts: allMdx(
      filter: { fields: { locale: { eq: $locale } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
    thumbnails: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "blog" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 75)
        }
      }
    }
  }
`;
