import React from "react";
import PropTypes from "prop-types";

const Card = ({ image, heading, summary = null }) => {
  return (
    <div className="card">
      <div className="image">{image}</div>
      <div className="title">
        <div className="heading">{heading}</div>
        {summary && (
          <div className="summary" data-testid="summary">
            {summary}
          </div>
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  image: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  summary: PropTypes.node,
};

export default Card;
